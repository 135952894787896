import  React, { FC, lazy } from 'react'
import themes, { ITheme } from './themes'
import routes, { IRoute } from './routes'
import Loading from '../../mui-base/components/Loading'
import { getDefaultRoutes } from "./defaultRoutes"
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"


export interface IConfig {
    containers: { 
        LayoutContainer: React.LazyExoticComponent<FC<{}>>
    },
    components: {
        Loading: () => React.ReactNode,
        Menu: React.LazyExoticComponent<FC<{}>>
    },
    auth: {
        signInURL: string
    },
    menu: {
        [key: string] : number | boolean | string | React.LazyExoticComponent<FC<{}>>
    },
    theme: {
        themes: ITheme[],
        defaultThemeID: ITheme["id"],
        defaultIsDarkMode: boolean,
        defaultIsRTL: boolean
    },
    pages: {
        PageNotFound: React.LazyExoticComponent<FC<{}>>,
        LandingPage2: React.LazyExoticComponent<FC<{}>>
    },
    getDefaultRoutes: (appConfig: any) => { path: string, element: ReactJSXElement }[],
    routes: IRoute[],
}

const config: IConfig = {
    containers: {
        LayoutContainer: lazy(() => import('../../mui-base/containers/LayoutContainer')),
    },
    components: {
        Loading,
        Menu: lazy(() => import('../../mui-base/containers/Menu')),
    },
    auth: {
        signInURL: '/signin',
    },
    menu: {
        width: 240,
        offlineIndicatorHeight: 12,
        initialAuthMenuOpen: false,
        initialMiniMode: false,
        initialMenuOpen: true,
        initialMobileMenuOpen: false,
        initialMiniSwitchVisibility: true,
        initialShowAvatar: true,
        MenuHeader: lazy(() => import('../../mui-base/components/MenuHeader')),
        MenuContent: lazy(() => import('../components/MenuContent')),
        MenuFooter: lazy(() => import('../../mui-base/components/MenuFooter')),
        useWindowWatcher: false,
    },
    theme: {
        themes,
        defaultThemeID: "default",
        defaultIsDarkMode: false,
        defaultIsRTL: false
    },
    pages: {
        PageNotFound: lazy(() => import('../../base/components/PageNotFound')),
        LandingPage2: lazy(() => import('../pages/LandingPage'))
    },
    getDefaultRoutes: getDefaultRoutes,
    routes,
}

export default config