import React, { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../providers/Auth'
import { useConfig } from '../providers/Config'


const UnauthorizedRoute: FC<{ children: React.ReactNode, redirectTo?: string }> = ({ children, redirectTo = '/', ...rest }) => {
    const { appConfig } = useConfig()
    const { auth: authConfig } = appConfig || {}
    const { redirectTo: _redirectTo = redirectTo } = authConfig || {}
    const { auth } = useAuth()

    if (!auth?.isAuthenticated) {
        return (<React.Fragment>{children}</React.Fragment>)
    } else {
        return <Navigate to={_redirectTo} replace />
    }
}

export default UnauthorizedRoute;