import { grey } from "@mui/material/colors"


interface IMuiColor {
    [key: number | string] : string
}

export interface ITheme {
    id: "default" | "red" | "green",
    source: { palette: { [key: string] : { [key: string]: string | IMuiColor } } }
    typography?: {
        [key: string] : string | number | { [key: string] : string }
    },
    color?: string
}

export const themes: ITheme[] = [
    {
        id: 'default',
        source: {
            palette: {
                mode: "light" as any,
                primary: {
                    main: "#232C54",
                    dark: "#191D31",
                    light: "#29356B",
                    contrastText: "#fff"
                },
                secondary: {
                    main: "#232c5480"
                },
                text: {
                    primary: "#000000",
                    secondary: "#757575",
                    disabled: "#bdbdbd"
                },
                success: {
                    main: "#2e7d32",
                    light: "#35D236"
                },
                error: {
                    main: "#d32f2f",
                    light: "#E73D3D"
                },
                warning: {
                    main: "#ED6C02",
                    light: "#FFA500"
                },
                grey: grey,
                white: {
                    main: "#fff"
                }
            }
        },
        typography: {
            fontFamily: [
                "Lab Grotesque",
                "sans-serif;"
            ].join(","),
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
            h1: {
                letterSpacing: "-0.125em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            h2: {
                letterSpacing: "-0.125em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            h3: {
                letterSpacing: "-0.0625em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            h4: {
                letterSpacing: "-0.03125em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            h5: {
                letterSpacing: "-0.03125em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            subtitle1: {
                letterSpacing: "0em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            subtitle2: {
                letterSpacing: "0em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            body1: {
                letterSpacing: "0em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            body2: {
                letterSpacing: "0em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            button: {
                letterSpacing: "0em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            caption: {
                letterSpacing: "0em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
            overline: {
                letterSpacing: "0em",
                fontFamily: ["Lab Grotesque", "sans-serif"].join(",")
            },
        },
    }
]

export function getTheme(name: string) {
    return themes.filter(t => t.id == name)[0] || themes[0]
}

export default themes